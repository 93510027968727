<template>
  <div class="main">
    <div class="logo">
      <img src="cake-v02.svg" />
    </div>
    <div class="settings">
      <img class="settings-wheel" v-bind:class="{ 'rotate': settingsActivated }" v-on:click="openSettings" src="settings.svg" />
      <button v-bind:class="{ 'settingsVisible': settingsActivated }" v-on:click="saveTokenLocal" class="btn" id="submit-token" type="button">Sichern</button>
      <input v-bind:class="{ 'settingsVisible': settingsActivated }" v-model="token" id="token" type="text" />
    </div>
    <div class="add-new">
      <label for="f-prename">{{ lPrename }}</label>
      <input v-bind:class="{ 'i-error': errPrename }" v-model="addPrename" id="f-prename" type="text" />
      <label for="f-lastname">{{ lLastname }}</label>
      <input v-bind:class="{ 'i-error': errLastname }" v-model="addLastname" id="f-lastname" type="text" />
      <label for="f-details">{{ lDetails }}</label>
      <input v-bind:class="{ 'i-error': errDetails }" v-model="addDetails" id="f-details" type="text" />
      <label for="f-birthday">{{ lBirthday }}</label>
      <input v-bind:class="{ 'i-error': errBirthday }" v-model="addBirthday" id="f-birthday" type="text" placeholder="DD.MM or DD.MM.YYYY" />
      <button class="btn" v-on:click="addOne">{{ addOrEdit }}</button>
      <span v-bind:class="{ 'f-success': fSuccess }" class="f-message">{{ fMessage }}</span>
    </div>
    <div class="table-section">
    <h1>UPCOMING</h1>
    <!-- preferences -->
    <div class="preferences-section">
      <div class ="pref-search">
        <label id="lbl-search">Suche: </label>
        <input type="text" v-model="searchTable" id="in-search" />
      </div>
      <div class ="pref-year">
        <label id="extra-type-vintage" class="extra-type-label" for="extra-type">Jahrgang</label>
        <label class="switch">
          <input id="extra-type" v-model="extraTypeYear" type="checkbox">
          <span class="slider round"></span>
        </label>
        <label id="extra-type-year" class="extra-type-label" for="extra-type">Alter</label>
      </div>
    </div>
    <div id="table-wrapper">
      <table>
        <tr class="table-header">
          <th>Vorname</th>
          <th>Nachname</th>
          <th>Details</th>
          <th class="th-birthday">Geburtstag</th>
          <th class="action-header">Aktion</th>
        </tr>
        <tr v-bind:class="{ 'has-birthday': hasBirthday(bdData.birthday), 'has-soon-birthday': hasSoonBirthday(bdData.birthday) }" v-for="(bdData, index) in sortedItems" :key="refreshKey">
          <th>{{ bdData.prename }}</th>
          <th>{{ bdData.lastname }}</th>
          <th>{{ bdData.details }}</th>
          <th>{{ convertDateFormat(bdData.birthday) }}{{ calculateExtra(bdData.birthday, bdData.year) }}</th>
          <th class="th-action"><img @click="editSingle(index, bdData.personId)" class="action-icon edit-icon" src="img/icons/edit_black.svg"><img @click="deleteSingle(index, bdData.personId)" class="action-icon delete-icon" src="img/icons/delete.svg"></th>
        </tr>
      </table>
    </div>

    <!--<button class="btn" id="btn-delete-all" v-on:click="deleteLocal">Daten löschen</button><br>-->
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import axios from 'axios';

var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);


export default {
  name: 'Home',
  props: {
    msg: String
  },
  data () {
    return {
      baseUrl: 'https://bd.derbeton.ch/api/',
      token: '',
      settingsActivated: false,
      lPrename: 'Vorname',
      lLastname: 'Nachname',
      lDetails: 'Details',
      lBirthday: 'Geburtstag',
      searchTable: '',
      tableDatas: [],
      extraTypeYear: true,
      isEdit: false,
      addOrEdit: 'Hinzufügen',
      editIndex: 0,
      addPrename: '',
      addLastname: '',
      addDetails: '',
      addBirthday: '',
      addYear: '',
      fMessage: '',
      fSuccess: false,
      errPrename: false,
      errLastname: false,
      errDetails: false,
      errBirthday: false,
      bdDatas: [],
      fullDateToday: '',
      dateToday: '',
      dateTodayYear: '',
      dateTodayMonth: '',
      dateTodayDay: '',
      refreshKey: 0,
    }
  },
  methods: {
    getToken() {

      if (localStorage.getItem('token') !== null) {
        this.token = localStorage.getItem('token');
        this.getData();
      } else {
        // nothing
      }
    },
    getData() {

    this.bdDatas = [];
    this.searchTable = '';
    this.tableDatas = this.bdDatas;

    if(this.token == '') {
      return;
    } 

    this.axios.post(this.baseUrl + "index.php", {
      token: this.token,
    })
    .then(res => (this.bdDatas = res.data));
    this.refreshKey +=1;
    },
    getDataLocal() {

      if (localStorage.getItem('arr') !== null) {
        this.bdDatas = JSON.parse(localStorage.getItem('arr'));
      }else{
        // nothing
      }

    },
    deleteLocal() {

      if (confirm('Willst du wirklich alle Daten löschen?')) {
        localStorage.removeItem('arr');
        this.bdDatas = [];
      } 

    },
    openSettings() {
      if(this.settingsActivated == true) {
        this.settingsActivated = false;
      } else {
        this.settingsActivated = true;
      }
    },
    getIndexByPersonId(personId) {

      var foundIndex;

      this.bdDatas.forEach((item, index) => {
        if(item.personId == personId) {
          foundIndex = index;
          return;
        }
      });

      return foundIndex;
    },
    deleteSingle(index, personId) {

      var deleteIndex = '';
      
      if(this.searchTable.length > 0) {
        deleteIndex = this.getIndexByPersonId(personId);
      } else {
        deleteIndex = index;
      }

      if (confirm('Willst du den Eintrag von ' + this.bdDatas[deleteIndex].prename + ' ' + this.bdDatas[deleteIndex].lastname + ' (' + this.convertDateFormat(this.bdDatas[deleteIndex].birthday) + ') wirklich löschen?')) {
        
        this.axios.post(this.baseUrl + "delete.php", {
        token: this.token,
        personId: this.bdDatas[deleteIndex].personId,
      })
      .then(res => (this.fMessage = res.data));
        
        this.bdDatas.splice(deleteIndex, 1);
      } 
      
      this.refreshKey +=1;
    },
    editSingle(index, personId) {

      this.isEdit = true;

      if(this.searchTable.length > 0) {
        this.editIndex = this.getIndexByPersonId(personId);
      } else {
        this.editIndex = index;
      }
      
      this.addPrename = this.bdDatas[this.editIndex].prename;
      this.addLastname = this.bdDatas[this.editIndex].lastname;
      this.addDetails = this.bdDatas[this.editIndex].details;

      if(this.bdDatas[this.editIndex].year == 0) {
        this.addBirthday = this.convertDateFormat(this.bdDatas[this.editIndex].birthday);
      } else {
        this.addBirthday = this.convertFullDateFormat(this.bdDatas[this.editIndex].year + '-' +this.bdDatas[this.editIndex].birthday);
      }
      

      window.scrollTo(0, 0);


    },
    orderItems(items) {
      return items.filter(item => {
        // console.log(item);
        if (item.personId) return item;
      });
    },
    notifyMe() {
      // Let's check if the browser supports notifications
      if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
      }

      // Let's check whether notification permissions have already been granted
      else if (Notification.permission === "granted") {
        // If it's okay let's create a notification
        var notification = new Notification("Hi there!");
      }

      // Otherwise, we need to ask the user for permission
      else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function (permission) {
          // If the user accepts, let's create a notification
          if (permission === "granted") {
            var notification = new Notification("Hi there!");
          }
        });
      }

      // At last, if the user has denied notifications, and you
      // want to be respectful there is no need to bother them any more.
    },
    setDateToday() {

      var now = dayjs();
      this.dateTodayYear = dayjs(now).format('YYYY');
      this.dateTodayMonth = dayjs(now).format('MM');
      this.dateTodayDay = dayjs(now).format('DD');
      this.dateToday = dayjs(now).format('MM-DD');
      this.fullDateToday = dayjs(now).format('YYYY-MM-DD');

    },
    hasBirthday(value){

      value = dayjs(value, ["MM-DD", "YYYY-MM-DD"], true);


      var dateWithoutYear = dayjs(value).format('MM-DD');

      return this.dateToday == dateWithoutYear;
  
    },
    hasSoonBirthday(value) {

      value = dayjs(value, ["MM-DD", "YYYY-MM-DD"], true);
      

      const onlyMonth = dayjs(value).format('MM');
      const onlyDay = dayjs(value).format('DD');

      if (onlyMonth == this.dateTodayMonth && onlyDay > this.dateTodayDay) {
        return true;
      }
      return false;

    },
    clearInput() {
      this.addPrename = '';
      this.addLastname = '';
      this.addDetails = '';
      this.addBirthday = '';
      this.addYear = '';
    },
    async postEdit() {
      return axios.post(this.baseUrl + 'update.php', {
          token: this.token,
          personId: this.bdDatas[this.editIndex].personId,
          prename: this.addPrename,
          lastname: this.addLastname,
          details: this.addDetails,
          birthday: this.addBirthday,
          year: this.addYear,
        }).then(response => response.data.success);
    

    },
    async postNew() {

    },
    async addOne() {
      this.errPrename = false;
      this.errLastname = false;
      this.errDetails = false;
      this.errBirthday = false;
      this.fSuccess = false;
      this.fMessage = '';
      var isFullDate = false;

      if(this.addPrename == '') {
        this.fMessage = 'Bitte einen Vornamen eingeben!'
        this.errPrename = true;
        return;
      }

      if(this.addLastname == '') {
        this.fMessage = 'Bitte einen Nachnamen eingeben!'
        this.errLastname = true;
        return;
      }

      if(this.addBirthday == '') {
        this.fMessage = 'Bitte ein Geburtsdatum eingeben!'
        this.errBirthday = true;
        return;
      }

      if(this.addBirthday.length > 5) {
        isFullDate = true;
        this.addYear = this.addBirthday.slice(6);
        console.log(this.addYear);
      }

      this.addBirthday = dayjs(this.addBirthday, ["DD.MM", "DD.MM.YYYY"], true);

      if(!dayjs(this.addBirthday).isValid()) {
        this.fMessage = 'Bitte gib ein gültiges Geburtsdatum ein!'
        this.errBirthday = true;
        return;
      }

      if(isFullDate) {
        this.addBirthday = dayjs(this.addBirthday).format('MM-DD');
      } else {
        this.addBirthday = dayjs(this.addBirthday).format('MM-DD');
        this.addYear = '';
      }
      
      // edited or not?
      if (this.isEdit) {

        try {
          var editSuccess = await this.postEdit();

          if(editSuccess === true) {
          // edit exisiting entry
          this.bdDatas[this.editIndex].prename = this.addPrename;
          this.bdDatas[this.editIndex].lastname = this.addLastname;
          this.bdDatas[this.editIndex].details = this.addDetails;
          this.bdDatas[this.editIndex].birthday = this.addBirthday;
          this.bdDatas[this.editIndex].year = this.addYear;

          this.fSuccess = true;
          this.fMessage = 'Eintrag wurde erfolgreich bearbeitet!';
          }

        } catch(error) {
          this.fSuccess = false;
          this.fMessage = 'Es gab ein Problem beim bearbeiten...';
        }

        this.clearInput();

      }

      if (!this.isEdit) {

        // send to API
        axios.post(this.baseUrl + 'add.php', {
          token: this.token,
          prename: this.addPrename,
          lastname: this.addLastname,
          details: this.addDetails,
          birthday: this.addBirthday,
          year: this.addYear,
        })
        .then(response => {
          this.bdDatas.push({personId: response.data.personId, prename: this.addPrename, lastname: this.addLastname , details: this.addDetails, birthday: this.addBirthday, year: this.addYear});
        
          this.clearInput();
        
        })
        .catch(err => {
            this.fSuccess = false;
            this.fMessage = 'Eintrag konnte nicht hinzugefügt werden';
        });

      }


    },
    convertDateFormat(date) {

      date = dayjs(date, ["MM-DD", "YYYY-MM-DD"]);
      
      return dayjs(date).format('DD.MM');

    },
    convertFullDateFormat(date) {
      date = dayjs(date, ["YYYY-MM-DD"]);
      return dayjs(date).format('DD.MM.YYYY');
    },
    calculatePosition() {

      const today = dayjs(this.fullDateToday);
      const yearToday = this.dateTodayYear;

      

      this.bdDatas.forEach(function (bdData, index) {
        const date2 = dayjs(yearToday + '-' + bdData.birthday);
        var position = date2.diff(today, 'day');

        // dates in past after dates in future
        if(position < 0) {
          position = 365 - Math.abs(position);
        }

        bdData.position = position;
        // set orig index for edit end delete
        bdData.origIndex = index;

        
        

      });

    },
    calculateExtra(date, year) {
      if(this.extraTypeYear) {
        return ' ' + this.calculateAge(date, year);
      }
      if(!this.extraTypeYear) {
        return this.calculateVintage(year);
      }
    },
    calculateAge(date, year) {

      if (year == '0000' | year == '') {
        return '';
      } 

      const fullDate = year + '-' + date;

      const fullDateJs = dayjs(fullDate);
      const fullDateToday = dayjs(fullDateToday)

      return '(' + fullDateToday.diff(fullDateJs, 'year') + ')';

    },
    calculateVintage(year) {
      if(year == '0000' | year == '') {
        return '';
      }

      return '.' + year;

    },
    saveTokenLocal() {
      window.localStorage.setItem('token', this.token);
      this.getData();
      this.settingsActivated = false;
    },
    saveDataLocal() {
      const data = JSON.stringify(this.bdDatas);
      window.localStorage.setItem('arr', data);
      // console.log(JSON.parse(window.localStorage.getItem('arr')));
    }
  },
  mounted() {
    this.getToken();
    this.setDateToday();
  },
  computed: {
    sortedItems() {

      if(!this.bdDatas.length) {
        return;
      }

      this.calculatePosition();

      this.tableDatas = this.bdDatas;
      

      if(this.searchTable.length > 0) {
        
        this.tableDatas = this.tableDatas.filter((item) => {
          return [item.prename, item.lastname, item.details, this.convertDateFormat(item.birthday)].join(' ')
            .toUpperCase()
            .includes(this.searchTable.toUpperCase())
        })
      }

      // return this.tableDatas;
      return this.tableDatas.sort((a,b) => (a.position - b.position));
    }

  },
  watch: {
    isEdit: function() {
      if (this.isEdit == true) {
        this.addOrEdit = 'Bearbeiten';
      } else {
        this.addOrEdit = 'Hinzufügen';
      }
    }
  }
}

</script>

<style scoped>

body, html {
  margin: 0;
}

/* button styles */

.btn {
  background-color: #0388A6;
  color: white;
  border: none;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

.btn:focus {
  outline: unset;
  background-color: #01a5ca;
}

/* end button styles */


h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.logo img {
    height: 80px;
}
.logo {
    position: absolute;
    top: 10px;
    right: 10px;
}

/* settings */

.settings img {
    height: 35px;
    cursor: pointer;
}
.settings {
    position: absolute;
    top: 15px;
    left: 15px;
}

.settings * {
    transition: opacity .5s ease-in-out;
}

#token {
    opacity: 0;
    height: 20px;
    float: right;
    margin-left: 15px;
    vertical-align: middle;
    margin-top: 5px;
    width: 200px;
}

#submit-token {
    opacity: 0;
    float: right;
    margin-left: 10px;
    margin-top: 5px;
    height: 25px;
    width: 70px;
}

.settingsVisible {
  opacity: 1 !important;
}

/* preferences */

.preferences-section {
    position: relative;
    height: 34px;
    width: 100%;
    text-align: right;
    margin-bottom: 15px;
}

.preferences-section .pref-search {
  position: absolute;
  bottom: 0;
  float: left;
}

#in-search {
  margin-left: 8px;
  height: 34px;
  width: 250px;
  border-radius: 5px;
  border: 1px solid #333;
}

#lbl-search {
  font-weight: bold;
}

.preferences-section .pref-year {
  position: absolute;
  bottom: 0;
  right: 0;
  float: right;
}



.extra-type-label {
    color: #333;
    font-weight: bold;
    padding: 8px;
    float: left;
}

#extra-type-year {
  float: right;
}

/* end settings icon */ 

.table-section {
  width: 900px;
  margin: auto;
}

table {
  border: 2px solid #0388A6;
  border-radius: 5px;
  width: 100%;
  margin: auto;
  float: none;
  text-align: left;
  margin-bottom: 20px;
}

th {
  padding: 15px 10px;
  font-weight: bold;
  color: #333;
}

tr {
  background-color: #24A62E;
  /* good: #60A60C, #24A62E*/
}

.table-header th {
  background-color: #0388A6;
  font-weight: bold;
  color: white;
}

.th-action {
    text-align: center;
    float: none;
    margin: auto;
    clear: none;
    max-width: 30px;
}

.action-header {
  text-align: center;
  max-width: 20px;
}

.action-icon {
    height: 15px;
    cursor: pointer;
    padding: 0 5px;
    margin: 0 5px;
}

.has-birthday {
  background-color: #F2431D !important;
}

.has-soon-birthday {
  background-color: #F2B41D;
}

/* input form */

.add-new {
    display: inline-grid;
    width: 450px;
    text-align: left;
}

.add-new label {
  font-weight: bold;
}

.add-new input {
    height: 40px;
    border-radius: 5px;
    border: 1px solid #333;
    margin: 10px 0px;
    font-size: 105%;
    padding-left: 5px;
}



.add-new button {
    height: 45px;
    font-weight: bold;
    font-size: 105%;
    margin-top: 20px;
}

#btn-delete-all {
    height: 45px;
    background-color: #F2431D;
    color: white;
    border: none;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    font-size: 105%;
    cursor: pointer;
    padding: 0 20px;
    max-width: 200px;
}

input:focus {
   outline: 1px solid #0388A6;
}

.i-error {
  /* outline: red solid 2px; */
  outline: red solid 2px;
}

.i-error:focus {
  outline: red solid 2px !important;
}

.f-message {
  color: red;
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
}

.f-success {
  color: green;
}



@media only screen and (max-width: 900px) {
  .add-new {
    width: 75%;
  }
  .table-section {
    width: 95%;
  }
}


 /* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0388A6;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #0388A6;
}

input:active + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
} 


/* animations */
.settings-wheel {
  transition: transform .5s ease-in-out;
}

.rotate {
  transform: rotate(90deg);
  /* animation: rotation .5s ease-in-out forwards; */
}


/* mobile css */

@media only screen and (max-width: 900px) {
  .logo {
    position: relative;
    top: -10px;
    right: unset;
  }
  .preferences-section .pref-search {
    text-align: left;
    width: 95%;
  }
  #in-search {
    width: 60%;
  }
  .extra-type-label {
    display: none;
  }
  #table-wrapper {
    overflow-x: auto;
  }
  .action-header {
    max-width: unset;
  }
  .action-icon {
    height: 15px;
    cursor: pointer;
    padding: unset;
    margin: unset;
  }
  .edit-icon {
    padding-right: 10px;
  }
}


/* keyframes */

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
}

</style>
